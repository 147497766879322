<template>
  <div :class="$style.image">
    <div id="left" :class="$style.left">
      <div :class="$style.header">
        <div :class="$style.title">
          <BjInput v-model="params.title" placeholder="请在这里输入标题" :max-length="50" />
          <span :class="$style.words">{{ params.title ? params.title.length : 0 }} / 50</span>
        </div>
        <div :class="$style.line" />
        <div v-if="!params.video.id" :class="$style.video" @click="onChoose()">
          <i class="ri-add-line" />
          <p :class="$style.title">点击选择或上传视频</p>
          <p :class="$style.tip">视频支持mp4，avi，wmv，mov，flv，rmvb，3gp，m4v，mkv格式；文件最大不超过2G。</p>
        </div>
        <div v-else :class="$style.videoActive">
          <div :class="$style.videoLeft">
            <div :class="$style.img">
              <img :src="params.video.cover" />
              <i :class="$style.icon" class="ri-play-circle-fill" @click="onPreview()" />
            </div>
          </div>
          <div :class="$style.videoRight">
            <div :class="$style.btn" @click="onChoose()">
              <span>
                <i class="ri-upload-line" />
              </span>
              <p>重新上传</p>
            </div>
            <div :class="[$style.btn, $style.btnDelete]" @click="onDelete()">
              <span>
                <i class="ri-delete-bin-7-line" />
              </span>
              <p>删除视频</p>
            </div>
          </div>
        </div>
      </div>
      <Detail ref="detail" :type="params.content_type" :data="params" is-video />
      <div :class="$style.footer" :style="{ width: footWidth }">
        <div :class="$style.num">视频大小：{{ num }}</div>
        <div :class="$style.btn">
          <BjButton type="primary" @click="onSave()">
            <i class="ri-save-line left" />
            立即保存
          </BjButton>
          <BjButton @click="onSave(true)"> 保存并上架 </BjButton>
          <BjButton :class="$style.ml20" @click="onSavePreview()"> 预览 </BjButton>
        </div>
      </div>
    </div>
    <!-- <div :class="$style.right">
      <div :class="$style.introduce">
        <p :class="$style.stitle">
          <span class="required">*</span>
          视频介绍
        </p>
        <a-textarea
          v-model="params.introduce"
          :auto-size="{ minRows: 15, maxRows: 15 }"
          placeholder="在这里输入视频的介绍内容，以便用户了解。"
        />
      </div>
    </div> -->
    <chooseMaterial :visible.sync="visible.choose" active="video" :allow="['video']" @getChoose="getChoose" />
  </div>
</template>

<script>
import _ from 'lodash'

import chooseMaterial from '@/components/chooseMaterial'
import { manageService } from '@/service'

import Detail from './Detail.vue'

const service = new manageService()

export default {
  name: 'AddVideo',
  components: {
    Detail,
    chooseMaterial,
  },
  data() {
    return {
      params: {
        content_type: 'video',
        title: null,
        content: null,
        video: {},
        introduce: null,
      },
      visible: {
        choose: false,
      },
      footWidth: '800px',
    }
  },
  computed: {
    num() {
      return this.params.video.file_size_format || '0M'
    },
  },
  created() {
    if (this.$route.params.id !== 'add') {
      this.getInfo()
    }
  },
  mounted() {
    this.footWidth = document.getElementById('left').offsetWidth + 'px'
    window.onresize = () => {
      this.footWidth = document.getElementById('left').offsetWidth + 'px'
    }
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.info({
          id: this.$route.params.id,
        })
        this.params = data
      } catch (e) {}
    },
    onPreview() {
      let tmp = [
        {
          cover: this.params.video.path,
          type: 'video',
        },
      ]
      this.BjPreview(tmp, this.params.video.path)
    },
    async onSave(type) {
      let param = _.cloneDeep(this.$refs.detail.params)
      let relation_poc = []
      this.$refs.detail.pocData.map(item => {
        relation_poc.push(item.id)
      })
      param.type_id = param.type_id ? param.type_id[param.type_id.length - 1] : null
      param.is_original_declare = param.is_original_declare ? 1 : 0
      param.group_id = Array.isArray(param.group_id) ? '' : param.group_id
      try {
        await service.save({
          info: JSON.stringify({
            ...this.params,
            ...param,
            relation_poc: relation_poc,
          }),
          id: this.$route.params.id !== 'add' ? this.$route.params.id : '',
          is_online: type ? 1 : 0, // 是否上架
        })
        this.$message.success('添加成功')
        this.$router.push({
          name: 'contentManage',
        })
      } catch (e) {}
    },
    getChoose(data) {
      this.params.video = data[0]
    },
    onChoose() {
      this.visible.choose = true
    },
    onDelete() {
      this.params.video = {}
    },
    async onSavePreview() {
      let param = _.cloneDeep(this.$refs.detail.params)
      let relation_poc = []
      this.$refs.detail.pocData.map(item => {
        relation_poc.push(item.id)
      })
      param.type_id = param.type_id ? param.type_id[param.type_id.length - 1] : null
      param.is_original_declare = param.is_original_declare ? 1 : 0
      param.group_id = Array.isArray(param.group_id) ? '' : param.group_id
      try {
        const res = await service.save({
          info: JSON.stringify({
            ...this.params,
            ...param,
            relation_poc: relation_poc,
          }),
          id: this.$route.params.id !== 'add' ? this.$route.params.id : '',
          is_online: 0,
        })
        if (this.$route.params.id === 'add') {
          this.$router.push({
            name: 'contentManageAddVideo',
            params: {
              id: res.data.id,
            },
          })
        }
        this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
        this.$router.push({
          name: 'frontPreview',
          query: {
            type: 'content',
            id: res.data.id,
          },
        })
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.image {
  .header {
    padding: 20px;
    background: #fff;

    .title {
      position: relative;

      .words {
        position: absolute;
        top: 10px;
        right: 5px;
      }

      :global {
        .ant-input {
          padding-right: 70px;
          font-size: 20px;
          border: none;
        }

        .ant-input:focus {
          border: none;
          box-shadow: none;
        }
      }

      ::-webkit-input-placeholder {
        color: #999;
        font-size: 20px;
      }
    }

    .line {
      width: 100%;
      border-top: 1px solid #eee;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    z-index: 99;
    display: flex;
    width: 880px;
    height: 60px;
    padding: 0 20px;
    line-height: 60px;
    background: #fff;

    .num {
      width: 200px;
    }

    .btn {
      flex: 1;
      text-align: right;
      /* stylelint-disable-next-line no-descending-specificity */
      :global {
        .ant-btn {
          width: 100px;
          height: 36px;
          margin-left: 10px;
          padding: 0;
        }
      }
    }

    .ml20 {
      margin-left: 20px !important;
    }
  }
}

.video {
  width: 100%;
  height: 240px;
  padding-top: 50px;
  text-align: center;

  i {
    color: #5c5c5c;
    font-size: 60px;
  }
  /* stylelint-disable-next-line no-descending-specificity */
  .title {
    color: #000;
    font-size: 14px;
  }

  .tip {
    color: #bbb;
    font-size: 12px;
  }
}

.video-active {
  display: flex;
  width: 100%;
  height: 240px;
  padding: 20px;

  .video-left {
    width: 395px;
    border-right: 1px solid #eee;

    .img {
      width: 355px;
      height: 200px;
      position: relative;

      img {
        width: 355px;
        height: 200px;
        border-radius: 6px;
      }

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 50px;
        color: #fff;
      }
    }
  }

  .video-right {
    flex: 1;
    padding-top: 60px;
    text-align: center;

    .btn {
      display: inline-block;
      width: 70px;
      text-align: center;
      cursor: pointer;

      p {
        margin-top: 10px;
        color: #000;
      }

      span {
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 48px;
        text-align: center;
        border: 1px solid #eee;
        border-radius: 25px;

        i {
          color: @primary-color;
          font-size: 24px;
        }
      }
    }

    .btn-delete {
      margin-left: 30px;

      i {
        color: @error-color !important;
      }
    }
  }
}

.stitle {
  color: #000;
  font-size: 14px;
}
</style>
